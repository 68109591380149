@import "./../../../assets/stylesheet/global/varibale";
@import "./../../../assets/stylesheet/global/mixin";

.b2b-modal {
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;

  .close-icon {
    cursor: pointer;
    line-height: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    vertical-align: middle;

    &:hover {
      svg {
        path {
          fill: $font-default-color;
        }
      }
    }
  }

  &-content {
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .3);
    cursor: default;
    margin-bottom: 50px;
    max-width: 400px;
    min-height: 150px;
    padding: 15px 20px 25px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &.mW600{
      max-width: 600px;
    }

    &.full-width {
      background-color: #eceff7;
      padding: 30px 40px;
      max-width: 1000px;

      .table-wrapper {
        thead {
          tr {
            th {
              &:last-child {
                width: 300px;
              }
            }
          }
        }
      }
    }
  }

  &-dialog {
    position: relative;
  }

  &-header {
    color: $light-font;
    font-family: Sky_Medium, sans-serif;
    font-size: 18px;
    
    &.center {
      text-align: center;
    }

    .gradient-text {
      display: inline-block;
      background-clip: text;
      -webkit-background-clip: text;
      background-image: linear-gradient(to right, #f08300, #e3000f 35%, #a72879 80%, #064497), linear-gradient(to bottom, #0092ed, #0092ed);
      -webkit-text-fill-color: transparent;
      font-size: 24px;
      padding-top: 20px;
      text-align: center;

      @include break-point(ie) {
        @include ie-text-gradient;
      }

      .value {
        font-size: 24px;
        opacity: 0.8;
        -webkit-text-fill-color: $font-default-color;
      }
    }
  }

  &.show {
    display: block;
  }
  .text-center{
    text-align: center;
  }

  .sub-heading {
    background-image: linear-gradient(to top, #ea1b2d, #a63283);
    display: inline-block;
    font-family: Sky_Medium, sans-serif;
    font-size: 27px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @include break-point(ie) {
      @include ie-text-gradient;
    }
  }
}

.activity-detail-wrapper{
  .b2b-modal-content{
    max-width: 600px;
    > .text-center{
      padding: 0;
    }
  }
}