@import "../../../../assets/stylesheet/global/varibale";
@import "../../../../assets/stylesheet/global/mixin";

.addNewUser {
//   .tab-strip-row {
//     background-color: $white;
//     border-bottom: 1px solid $grey;

//     .list-tab {
//       border-bottom: 0;
//       margin-bottom: 0;
//       padding-right: 30px;
//       padding-left: 30px;
//       width: 52%;
//     }
//   }

    .dropdown-control {
      border: 1px solid $grey;
      border-radius: 6px;
      font-size: 14px;
      padding: 8px 10px;
      width: 100%;
      background: #fff;
      color: #556067;
      option {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    

    // .description-textarea {
    //   border-color: $grey;
    //   border-radius: 6px;
    //   height: 200px;
    //   font-size: 18px;
    //   padding: 20px;
    //   resize: none;
    //   width: 100%;

    //   &::placeholder {
    //     color: $light-font;
    //   }
    // }

    .button {
      font-size: 24px;
      height: 48px;
      width: 240px;
    }
  }
}