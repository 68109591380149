@import './varibale';
$table-border: 1px solid #dbe0ed;

.table-wrapper {
  border-collapse: separate;
  border-spacing: 0;
  font-size: 16px;
  width: 100%;

  thead {
    background-color: #f7f9fd;

    tr {
      font-family: Sky_Medium, sans-serif;

      th {
        border-top: $table-border;
        border-bottom: $table-border;
        height: 40px;
        padding-right: 5px;
        padding-left: 20px;
        width: 140px;

        &:first-child {
          border-left: $table-border;
          border-top-left-radius: 6px;
        }

        &:last-child {
          border-right: $table-border;
          border-top-right-radius: 6px;
        }
        .table-sort-icon{
          position: relative;
          cursor: pointer;
          &:after, &:before {
            content: '';
            height: 0;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $font-default-color;
            border-left: 5px solid transparent;
            position: absolute;
            top: 5px;
            right: -20px;
            width: 0;
          }
          &:after{
            transform: rotate(180deg);
            top: 12px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      background-color: $white;

      td {
        height: 35px;
        padding-right: 5px;
        padding-left: 20px;

        &:first-child {
          border-left: $table-border;
        }

        &:last-child {
          border-right: $table-border;
        }
      }

      &:hover {
        background-color: #f4fafe;
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 6px;
          }

          &:last-child {
            border-bottom-right-radius: 6px;
          }
        }
      }
    }

    td {
      border-bottom: $table-border;
      font-family: Sky_Regular, sans-serif;
    }
  }

  &.edit-table {
    th {
      width: 30%;
    }
  }

  .checkbox-element {
    padding-top: 16px;
    display: inline-block;
    padding-left: 20px;
  }
}

.edit-icon {
  background: $primary-color;
  border-radius: 50%;
  cursor: pointer;
  height: 27px;
  line-height: 30px;
  text-align: center;
  width: 27px;
}