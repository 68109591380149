@import "../../assets/stylesheet/global/varibale";
@import "../../assets/stylesheet/global/mixin";

.transaction-history {
  .monthly-statement {
    position: relative;

    .statement-btn {
      position: absolute;
      top: 24px;
      right: 0;
    }
  }
}

.transaction-table {

  th {
    &:first-child, &:nth-child(2) {
      width: 150px;
    }

    &:nth-child(3) {
      width: 800px;
    }

    &:last-child {
      padding-right: 20px;
      text-align: right;
    }
  }

  tbody {
    td {
      &:nth-child(4) {
        //background: url("../../assets/images/icon-download.png") no-repeat;
        text-align: center;
      }

      &:last-child {
        padding-right: 20px;
        text-align: right;

        &:before {
          content: "\20B9";
          margin-right: 4px;
        }

        //&:after {
        //  content: url("../../assets/images/icon-info-copy.png");
        //  margin-left: 10px;
        //  vertical-align: middle;
        //}
      }
    }
  }
}
.cr{
  color: #51b684;
}
.dr{
  color: #d72145;
}
.no-data-found {
  display: flex;
  color: #ff510c;
  font-family: Sky_Medium, sans-serif;
  font-size: 20px;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 40px;
}