@import "../../../../assets/stylesheet/global/varibale";
@import "./../../../../assets/stylesheet/global/mixin";

.navbar {
  align-items: center;
  display: inline-flex;
  margin-right: 15px;//35px
  margin-bottom: 0;
  margin-left: 15px; //40px

  @include break-point(tablet-view) {
    display: flex;
    margin-right: 0;
    margin-left: 0;
  }

  .option {
    font-family: Sky_Medium, sans-serif;
    font-size: 18px;
    padding: 33px 16px;
    position: relative;

    .disabled-link {
      opacity: .3;
      pointer-events: none;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      color: $light-font;

      &.active {
        color: $font-default-color;

        &:after {
          background-image: linear-gradient(to right, #f08300, #e3000f 35%, #a72879 80%, #064497);
          border-radius: 2px;
          content: '';
          height: 4px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }

      &:hover {
        color: $font-default-color;
      }
    }

    @include break-point(tablet-view) {
      font-family: Sky_Regular, sans-serif;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 5px 10px;
    }

    @include break-point(ie) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

