@import "../../assets/stylesheet/global/varibale";
@import "../../assets/stylesheet/global/mixin";

.temporarily-suspend {
  .suspend-form-container {
    background-color: $white;
    border: solid 1px $grey;
    border-radius: 6px;
    margin-bottom: 80px;
  }

  .separate-line {
    border-bottom: 1px solid $grey;
    margin-bottom: 20px;
  }

  .title {
    color: $light-font;
    font-size: 16px;
    margin-top: -3px;
  }

  .information {
    font-family: Sky_Medium, sans-serif;
    font-size: 21px;
    line-height: 1;

    &.gradient-text {
      background-image: linear-gradient(to right, #ea1b2d, #a63283), linear-gradient(to bottom, #343940, #343940);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;

      @include break-point(ie) {
        @include ie-text-gradient;
      }
    }
  }

  .date-picker-container {
    display: inline-flex;
    position: relative;

    .react-datepicker__input-container {
      z-index: 1;

      input {
        background-color: transparent;
        border: solid 1px $grey;
        border-radius: 6px;
        font-family: Sky_medium, sans-serif;
        font-size: 18px;
        max-width: 360px;
        padding: 10px 60px;
        width: 100%;

        &:disabled {
          background-color: #efefef;
          color: $grey;
        }
      }
    }

    .react-datepicker-popper {
      z-index: 9;
    }

    &:after {
      content: 'From - ';
      align-self: center;
      color: $light-font;
      font-size: 15px;
      position: absolute;
      top: 13px;
      left: 15px;
      z-index: 1;
    }

    &.to-label {
      .react-datepicker__input-container {
        input {
          padding-right: 42px;
          padding-left: 42px;
        }
      }

      &:after {
        content: 'To - ';
      }

      &.secondary {
        .react-datepicker__input-container {
          input {
            padding-right: 60px;
            padding-left: 60px;
          }
        }
      }
    }

    .calendar-icon {
      align-self: center;
      position: absolute;
      top: 11px;
      right: 15px;
      z-index: 0;
    }

    &.disabled {
      .calendar-icon {
        z-index: 2;

        svg {
          path {
            fill: #cbcfd1;
          }
        }
      }

      &:after {
        color: #cbcfd1;
      }
    }
  }

  .disclaimer-text {
    color: red;
    font-weight: 600;
    width: 22%;
    display: inline-flex;
    margin-bottom: 10px;
    font-size: 16px;
    vertical-align: middle;
  }
}

@media screen and (max-width: 767px) {
  .disclaimer-text {
    width:auto !important;
    margin-top:10px;
  }
}