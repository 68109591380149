@import "./../../assets/stylesheet/global/mixin";
@import "../../assets/stylesheet/global/varibale";

.b2b-escalation-matrix {
  .escalation-table {
    tbody {
      td {
        &:nth-child(2) {
          color: $primary-color;
        }

        &:last-child {
          position: relative;
          padding-left: 45px;

          &:before {
            content: url("../../assets/images/icon-call.png");
            position: absolute;
            top: 8px;
            left: 20px;
          }
        }
      }
    }
  }
}