@import "../../assets/stylesheet/global/varibale";
@import "./../../assets/stylesheet/global/mixin";

.b2b-bulk-modify {
  .action-row {
    min-height: 80px;
  }

  .select-pack {
    background: $white;
    border-color: $grey;
    border-radius: 4px;
    cursor: pointer;
    font-family: Sky_Regular, sans-serif;
    height: 48px;
    text-indent: 5px;
    max-width: 240px;
    width: 100%;
    padding: 10px;

    &:focus {
      border: 1px solid $primary-color;
      outline: none;
    }

    @include break-point(ie) {
      max-width: 225px;
    }

    &:disabled {
      background-color: $grey;
      cursor: not-allowed;
    }
  }

  .search-container {
    align-self: unset;
    width: 520px;
  }

  td {
    &:first-child {
      width: 10%;
    }

    &:nth-child(2) {
      width: 25%;
    }
  }

  .modify-pack-table {
    td {
      &:first-child {
        width: 20%;
      }

      &:nth-child(2) {
        width: 20%;
      }
    }
  }
}