@import "./../../../assets/stylesheet/global/varibale";

.button {
  background-color: transparent;
  border: solid 1px $primary-color;
  border-radius: 6px;
  color: $primary-color;
  cursor: pointer;
  font-family: Sky_Medium, sans-serif;
  font-size: 16px;
  padding: 8px 20px;
  text-align: center;

  &.shadow {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
  }

  &.full {
    width: 100%;
  }

  &.fill {
    background-color: $primary-color;
    color: $white;
  }

  &.big {
    //height: 45px;
    min-height: 45px;
  }

  &.disabled,
  &[disabled] {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    cursor: default;
  }

  &.no-radius {
    border-radius: 0;
  }

  &.border {
    border: 1px solid $grey;
    color: $font-default-color;
  }

  &.white-bg {
    background-color: $white;
  }

  &.w-140 {
    width: 140px;
  }
}

.text-btn {
  color: $primary-color;
  cursor: pointer;
  font-family: Sky_Medium, sans-serif;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
}