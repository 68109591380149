@import "./../../assets/stylesheet/global/varibale";

.page-error-container {
  .logo {
    cursor: pointer;
    height: 38px;
    margin-right: 72px;
  }

  .inner {
    text-align: center;

    .description {
      color: $light-font;
      font-size: 16px;
      text-align: center;
    }

    .error-icon {
      line-height: 0;
      vertical-align: middle;
    }

    .link {
      color: $font-default-color;
      font-family: Sky_Medium, sans-serif;
      font-size: 18px;

      &:hover {
        color: $primary-color;
      }
    }
  }
}