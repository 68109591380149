@import "../global/varibale";
@import "../global/mixin";

.list-tab {
  border-bottom: 1px solid $grey;
  display: flex;
  justify-content: space-between;
  padding-right: 85px;
  padding-left: 85px;

  @include break-point(mobile-view) {
    padding-left: 20px;
    padding-right: 20px;
  }

  li {
    border-bottom: 4px solid transparent;
    color: #6c757d;
    cursor: pointer;
    font-family: Sky_Medium, sans-serif;
    font-size: 20px;
    letter-spacing: 0.48px;
    list-style-type: none;
    padding-right: 30px;
    padding-bottom: 8px;
    padding-left: 30px;
    position: relative;

    &.active {
      color: $font-default-color;

      &:after {
        background-image: linear-gradient(to right, #f08300, #e3000f 35%, #a72879 80%, #064497);
        border-radius: 2px;
        content: '';
        position: absolute;
        left: 0;
        bottom: -6px;
        height: 4px;
        width: 100%;
      }
    }
  }
}