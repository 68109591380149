@import "varibale";

// font face mixin

@mixin font-type($name, $location) {
  @font-face {
    font-family: $name;
    src: url($location)
  }
}

// conditional scss for media query
@mixin break-point($point) {
  @if $point == tablet-view {
    @media screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $point == mobile-view {
    @media screen and (max-width: 600px) {
      @content;
    }
  } @else if $point == ie {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @content;
    }
  }
}

@mixin page-heading {
  background-image: linear-gradient(to right, #ea1b2d, #a63283), linear-gradient(to bottom, #0092ed, #0092ed);
  display: inline-block;
  font-size: 41px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @include break-point(tablet-view) {
    font-size: 30px;
  }

  @include break-point(ie) {
    @include ie-text-gradient;
  }
}

@mixin header-footer-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: $wrapper-size; //actual width 1260 + 30 padding
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

@mixin wrapperContent {
  margin-right: auto;
  margin-left: auto;
  max-width: $content-wrapper-size; //actual width 1140 + 30 padding
  min-height: calc(100vh - 207px);
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@mixin ie-text-gradient {
  background: none;
  color: #ea1b2d;
}
