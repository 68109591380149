.server-error {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 238px);
  padding-top: 100px;
  text-align: center;

  &-icon {
    line-height: 0;
    vertical-align: middle;
  }

  .go-to-text {
    font-family: Sky_Medium, sans-serif;
    font-size: 18px;
  }
}