@import "mixin";

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #eceff7;
  color: $font-default-color;
  font: 14px/1.33 "Sky_Regular",sans-serif;
}

a,
i,
span,
strong {
  display: inline-block;
}
ul,
li {
  list-style-type: none;
}
ul {
  padding-left: 0;
}

a,
input,
button,
textarea {
  outline: none;

  &:focus {
    outline: none;
  }
}
a {
  &:hover {
    text-decoration: none;
  }
}

hr {
  background: $grey;
  border: 0;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;

  &.no-top {
    margin-top: 0;
  }

  &.no-bottom {
    margin-bottom: 0;
  }

  &.no-space {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.medium-strong {
  font-family: Sky_Medium, sans-serif;
}

.wrapper {
  @include header-footer-wrapper;
}

.content-wrapper {
  @include wrapperContent;
  padding-top: 120px;

  @include break-point(tablet-view) {
    padding-top: 175px;
  }
}

.heading {
  @include page-heading;
}

.prev-page-link {
  color: $light-font;
  cursor: pointer;
  display: inline-block;
  font-family: Sky_Medium, sans-serif;
  font-size: 18px;
  line-height: 1;
  position: relative;
  user-select: none;

  .back-link {
    line-height: 0;
    position: absolute;
    top: 1.35px;
    left: -40px;
  }

  &:hover {
    color: $font-default-color;
  }
}

.search-container {
  align-self: flex-end;
  width: 550px;

  .form-element {
    .search-icon {
      line-height: 0;
      position: absolute;
      top: 15px;
      left: 20px;
      &.search-cross-icon{
        right: 20px;
        left: auto;
        cursor: pointer;
      }
    }
  }

  .input-element {
    &:disabled {
      background-color: $grey;
      cursor: not-allowed;
    }
  }

  @include break-point(tablet-view) {
    width: 300px;
  }
}

.dueDateColor{
  color: $red;
}
.status{
  &-active{
    -webkit-text-fill-color: green;
    color: green;
  }

  &-deactivated{
    -webkit-text-fill-color: $red;
    color: $red;
  }

  &-temp-suspension{
    -webkit-text-fill-color: orange;
    color: orange;
  }
}

.successful, .success {
  color: #51b684;
}

.submitted {
  color: #eb7c23;
}

.completed {
  color: #234094;
}

.failure, .fail {
  color: #e00014;
}

.inprogress{
  color: orange;
}
//css used for Hiding captcha text
.grecaptcha-badge {
  width: 70px !important;
  right: 0 !important;
}

.reset-password{
  margin: 0 auto;
  max-width: 400px;
  padding-right: 15px;
  padding-left: 15px;
}