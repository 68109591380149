@import "../../../assets/stylesheet/global/varibale";

.tooltip-element {
  background-color: #a63283;
  border-radius: 6px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.32);
  color: $white;
  display: none;
  font-size: 15px;
  line-height: 1.4;
  position: absolute;
  top: 38px;
  right: 0;
  width: 260px;
  z-index: 99;

  &:after {
    width: 0;
    height: 0;
    border-right: 10px inset transparent;
    border-bottom: 10px inset #a63283;
    border-left: 10px solid transparent;
    position: absolute;
    content: "";
    top: -8px;
    right: 16px;
  }
}