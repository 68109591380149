@import "./../../assets/stylesheet/global/varibale";

.transaction-status-container {
  padding-top: 150px;
  text-align: center;

  .icon {
    line-height: 0;
    vertical-align: middle;
  }

  .message {
    font-family: Sky_Medium, sans-serif;
    font-size: 24px;

    &.error {
      color: #d03416;
    }

    &.success {
      color: #51b684;
    }
  }

  .title {
    color: $light-font;
    font-size: 16px;
  }

  .value {
    font-family: Sky_Medium, sans-serif;
    font-size: 24px;
  }
}