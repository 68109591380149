@import "../../../../assets/stylesheet/global/varibale";
@import "../../../../assets/stylesheet/global/mixin";

.b2b-modal {
  &-info {
    display: flex;
    justify-content: space-between;

    .column {
      .title {
        font-family: Sky_Medium, sans-serif;
        font-size: 16px;
      }

      .value {
        background-image: linear-gradient(to right, #ea1b2d, #a63283);
        display: inline-block;
        font-family: Sky_Medium, sans-serif;
        font-size: 27px;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @include break-point(ie) {
          @include ie-text-gradient;
        }
      }
    }
  }
}