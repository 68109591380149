@import "./../../../assets/stylesheet/global/varibale";
@import "./../../../assets/stylesheet/global/mixin";

.footer-container {
  background-color: $white;
  width: 100%;
  position: relative;
  .last-login-info{
    position: absolute;
    max-width: 200px;
    font-size: 12px;
    bottom: 30px;
    right: 0;
    padding: 10px;
    //color: $grey;
    background: #eceff7;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0 3px 10px 0 rgba(161, 168, 188, .5);
    .ip-address {
      word-break: break-all;
    }
  }

  .inner {
    text-align: center;
    max-width: 900px;
    margin: 0 auto;

    .heading {
      background-image: linear-gradient(to right, #f08300, #e3000f 35%, #a72879 80%, #064497), linear-gradient(to bottom, #0092ed, #0092ed);
      color: #0092ed;
      display: inline-block;
      font-size: 36px;
      font-weight: 400;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;

      @include break-point(ie) {
        @include ie-text-gradient;
      }
    }

    .social-connection-list {
      .link {
        display: inline-flex;
        list-style-type: none;
        margin-right: 10px;

        .icon {
          background: url('./../../../assets/images/Social_sprite.png') no-repeat;
          background-position-y: -2px;
          width: 32px;
          height: 32px;

          &.twitter {
            background-position-x: -3px;
          }

          &.youtube {
            background-position-x: -46px;
          }

          &.facebook {
            background-position-x: -89px;
          }

          &.instagram {
            background-position-x: -132px;
          }

          &.feed {
            background-position-x: -175px;
          }

          &.snapchat {
            background-position-x: -218px;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .description{
      @media (max-width: 1240px) {
        padding-bottom: 80px;
      }
    }
  }
}