.content-loader {
  margin-left: auto;
  width: 150px;

  .animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loader;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 20%, #eeeeee 50%);
    border-radius: 4px;
    height: 10px;
    margin-bottom: 5px;
    width: 100%;

    &:last-child {
      width: 100px;
      margin-left: auto;
    }
  }

  &.cell-data {
    display: inline-block;
    vertical-align: middle;
    width: 50px;

    .animated-background {
      height: 20px;

      &:last-child {
        width: 50px;
      }
    }
  }
}

@keyframes loader {
  0% {
    background-position: -80px;
  }
  100% {
    background-position: 80px;
  }
}