@import "./../../assets/stylesheet/global/mixin";

.b2b-bulk-recharge {
  //----Subscriber Details----
  .action-row {
    min-height: 81px; //find better solution for this

    .total-amount {
      font-size: 21px;

      .rupees {
        font-family: Indian_Rupee, sans-serif;
      }

      .form-element {
        display: inline-block;

        .input-element {
          margin-bottom: 0;
          padding-right: 10px;
          width: 90px;
        }
      }
      .button{
        &:disabled{
          color: $font-default-color;
          cursor: not-allowed;
          background-color: $grey;
        }
      }
    }

    .edit-total {
      background-color: $white;
      border: solid 1px $grey;
      border-radius: 6px;
      width: 98px;
    }
  }

  //----Select Recharge Table----
  .recharge-details {
    table-layout:fixed; //used to fix table column width
    th {
      width: 90px;
      &:first-child {
        width: 20px;
      }
      &:nth-child(3){
        width: 200px;
      }
      &:nth-child(5), &:nth-child(6){
        width: 75px;
      }
    }

    tr {
      td {
        &:nth-child(5) { //4
          &:before {
            content: '\20B9';
            margin-right: 4px;
          }
        }

        &:nth-child(6) { //5
          &:before {
            content: '\20B9';
            margin-right: 4px;
          }
        }
      }
    }
  }

  //----Edit Recharge Table----
  .edit-table {
    .recharge-edit {
      position: relative;

      .recharge-amount {
        width: 70px;
      }

      .edit-amount {
        background-color: $white;
        border: solid 1px $grey;
        border-radius: 6px;
        width: 80px;
      }

      .close-edit {
        cursor: pointer;
        margin-top: 4px;
      }

      .confirm-icon {
        cursor: pointer;
        margin-top: 5px;
      }
    }

    .form-element {
      input {
        margin-bottom: 0;
        padding: 5px;
        width: 70px;
      }
    }
  }
}

